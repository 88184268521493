import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Lazy from "react-lazyload"

import Type from "@nerdwallet/react-typography/structured-content"
import TrackedButton from "@src/components/TrackedButton"
import loanProcessImage from "./man-with-phone.svg"
import { useApplyUrl } from "@src/hooks/useApplyUrl"

import styles from "./LoanProcess.module.less"

const CTA_TYPE = "Loan Process CTA"

const LoanProcessCta = () => {
  const applyUrl = useApplyUrl({
    cta_type: CTA_TYPE,
  })

  return (
    <div className={styles.ctaAlign}>
      <TrackedButton
        secondary
        className={classNames(styles.stepComponent, "clickable")}
        href={applyUrl}
        data-cta-type={CTA_TYPE}
        target="_blank"
      >
        GET STARTED
      </TrackedButton>
    </div>
  )
}

const LoanProcessStep = ({ step: { title, content, type }, index }) => (
  <div key={title} className={styles.step}>
    <Type size="2" bold className={styles.stepTitle}>
      {`${index + 1}. ${title}`}
    </Type>
    <Type className={styles.stepContent}>{content}</Type>
    {type === "cta" && <LoanProcessCta />}
  </div>
)

LoanProcessStep.propTypes = {
  step: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  index: PropTypes.number,
}

const LoanProcess = props => {
  const { introParagraph, steps } = props

  return (
    <>
      <Type size="2" align="center" className={styles.introParagraph}>
        {introParagraph}
      </Type>

      <div className={styles.columns}>
        <div className={styles.imageColumn}>
          <Lazy height={468}>
            <img
              className={styles.image}
              src={loanProcessImage}
              alt="man with a laptop on a bench"
              width="100%"
              height={468}
            />
          </Lazy>
        </div>
        <div className={styles.stepsColumn}>
          <div className={styles.steps}>
            {steps.map((step, index) => (
              <LoanProcessStep key={step.title} step={step} index={index} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

LoanProcess.propTypes = {
  introParagraph: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      type: PropTypes.oneOf(["link", "cta"]),
    })
  ),
}

LoanProcess.defaultProps = {
  introParagraph:
    "Getting a business loan can be challenging, and several factors — cost, " +
    "speed, repayment schedule — can play a role in deciding which product is " +
    "right for you. Let us help you through the process.",
  steps: [
    {
      title: "Fill out one simple application",
      content:
        "Answer a few questions about your business to get personalized lending " +
        "options. It’s free and won’t impact your credit score.",
    },
    {
      title: "See your business loan options",
      content:
        "It takes about three minutes to get options from our network of vetted " +
        "small-business lenders. Compare interest rates and repayment terms to " +
        "choose the best product for your needs.",
    },
    {
      title: "Get your loan",
      content:
        "If the lender approves you, you’ll sign closing documents in order " +
        "to receive funds. Some lenders can approve and fund loans within one " +
        "business day.",
      type: "cta",
    },
  ],
}

export default LoanProcess
