import React from "react"
import PropTypes from "prop-types"

import Columns from "@src/components/Columns/Columns"
import Column from "@src/components/Columns/Column"
import CentralizedColumn from "@src/components/CentralizedColumn"

const Proceeds = ({ children }) => {
  return (
    <CentralizedColumn small={React.Children.count(children) <= 6}>
      <Columns gap>
        {React.Children.map(children, child => {
          return <Column width={{ mobile: 6, desktop: 4 }}>{child}</Column>
        })}
      </Columns>
    </CentralizedColumn>
  )
}

Proceeds.propTypes = {
  applyUrl: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  ctaType: PropTypes.string.isRequired,
}

export default Proceeds
