import React from "react"
import PropTypes from "prop-types"

import { formatCurrency, slugify } from "@src/lib/helpers"
import AnchorLink from "@src/components/AnchorLink"
import Table from "@src/components/Table"
import { Box } from "@nerdwallet/currency"

import styles from "./SimpleTableDesktop.module.less"

const COLUMNS = [
  { id: "lender", heading: "Lender" },
  { id: "bestFor", heading: "Best for" },
  { id: "maxLoanAmount", heading: "Max loan amount" },
  { id: "minCreditScore", heading: "Min. credit score" },
  { id: "estApr", heading: "APR range" },
  { id: "maxLoanTerm", heading: "Max term length" },
]
const REVIEW_TYPE = "Simple Table Review"
const CTA_TYPE = "Simple Table Lender Name"

const standardizeContent = (value, reviewLink, options = {}) => {
  if (!value) return defaultContent(reviewLink)

  const formatter = options.formatter || (input => input)
  const className = options.className || ""

  return <span className={className}>{formatter(value)}</span>
}

const defaultContent = reviewLink => {
  if (!reviewLink) return <span>-</span>

  return (
    <a href={reviewLink} data-cta-type={REVIEW_TYPE}>
      See review
    </a>
  )
}

const buildLenderLink = (institutionName, reviewLink, linkTarget) => {
  if (!institutionName) return defaultContent(reviewLink)

  return (
    <AnchorLink target={slugify(linkTarget)} ctaType={CTA_TYPE}>
      {institutionName}
    </AnchorLink>
  )
}

const buildTableRow = ({
  name,
  bestFor,
  maxLoanAmount,
  minCreditScore,
  estApr,
  maxLoanTerm,
  reviewLink,
  linkTarget,
}) => {
  return {
    lender: buildLenderLink(name, reviewLink, linkTarget),
    minCreditScore: standardizeContent(minCreditScore, reviewLink),
    maxLoanTerm: standardizeContent(maxLoanTerm, reviewLink),
    bestFor: standardizeContent(bestFor, reviewLink, {
      className: styles.nowrap,
    }),
    estApr: standardizeContent(estApr, reviewLink, {
      className: styles.nowrap,
    }),
    maxLoanAmount: standardizeContent(maxLoanAmount, reviewLink, {
      formatter: formatCurrency,
    }),
  }
}

const SimpleTableDesktop = ({ lenders }) => (
  <Box
    display={{ mobile: "none", desktop: "block" }}
    className={styles.overflowAuto}
  >
    <Table
      columns={COLUMNS}
      data={lenders.map(buildTableRow)}
      simpleTable
      showLastColumnOnMobile
      stickyHeader
    />
  </Box>
)

SimpleTableDesktop.propTypes = {
  lenders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      reviewLink: PropTypes.string,
      linkTarget: PropTypes.string,
      bestFor: PropTypes.string,
      minCreditScore: PropTypes.number,
      estApr: PropTypes.string,
      maxLoanTerm: PropTypes.string,
      maxLoanAmount: PropTypes.number,
    })
  ),
}

export default SimpleTableDesktop
