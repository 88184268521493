import React from "react"
import PropTypes from "prop-types"

import trustpilotLogo from "./svg/trustpilot-logo.svg"
import styles from "./TrustpilotLogo.module.less"

const TrustpilotLogo = ({ identifyingName }) => (
  <a
    id="tp-widget-logo"
    className="profile-url"
    target="_blank"
    rel="noreferrer"
    href={`https://www.trustpilot.com/review/${identifyingName}?utm_medium=trustbox&amp;utm_source=Horizontal`}
    style={{
      height: "100%",
      width: "100%",
    }}
  >
    <img
      className={styles.logo}
      src={trustpilotLogo}
      alt="trustpilot logo"
      width={110}
      height={26}
    />
  </a>
)

TrustpilotLogo.propTypes = {
  identifyingName: PropTypes.string,
}

export default TrustpilotLogo
