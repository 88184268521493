import React from "react"
import PropTypes from "prop-types"

import MiniTable from "@src/components/MiniTable"
import { Box } from "@nerdwallet/currency"
import { formatCurrency } from "@src/lib/helpers"

const CTA_TYPE = "Simple Table Mobile CTA"
const MINI_TABLE_ROWS = [
  { id: "name", formatter: "accent" },
  { id: "maxLoanAmount", heading: "Max Loan" },
  { id: "minCreditScore", heading: "Min. Credit Score" },
  { id: "estApr", heading: "APR Range" },
  { id: "maxLoanTerm", heading: "Max Term Length" },
  { id: "viewDetails", formatter: "button" },
]

const buildMiniTableRow = lender => {
  const { linkTarget, name, maxLoanAmount } = lender
  return {
    ...lender,
    maxLoanAmount: maxLoanAmount && formatCurrency(maxLoanAmount),
    viewDetails: {
      linkTarget: linkTarget,
      institutionName: name,
      ctaType: CTA_TYPE,
    },
  }
}

const SimpleTableMobile = ({ lenders }) => (
  <Box width={100} display={{ mobile: "block", desktop: "none" }}>
    <MiniTable rows={MINI_TABLE_ROWS} data={lenders.map(buildMiniTableRow)} />
  </Box>
)

SimpleTableMobile.propTypes = {
  lenders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string,
      maxLoanAmount: PropTypes.number,
      minCreditScore: PropTypes.number,
      estApr: PropTypes.string,
      maxLoanTerm: PropTypes.string,
      linkTarget: PropTypes.string,
    })
  ),
}

export default SimpleTableMobile
