import React from "react"
import PropTypes from "prop-types"

import { CenteredContainer } from "@nerdwallet/react-layout"
import Type, { Title } from "@nerdwallet/react-typography"

import { useApplyUrl } from "@src/hooks/useApplyUrl"
import buildTargetUrl from "@src/lib/buildTargetUrl"
import {
  buildElementPositionAttributes,
  buildProductShoppingAttributes,
} from "@src/lib/analytics_payloads"

import funderaLogo from "@src/svg/fundera-by-nw.svg"
import dollar from "@src/svg/dollar.svg"
import shop from "@src/svg/shop.svg"
import global from "@src/svg/global.svg"
import shoppingCart from "@src/svg/shopping-cart.svg"
import setting from "@src/svg/setting.svg"
import audit from "@src/svg/audit.svg"
import branches from "@src/svg/branches.svg"

import Disclosure from "./Disclosure"
import Proceeds from "./Proceeds"
import ProceedTile from "./ProceedTile"

import styles from "./UseOfProceedsHero.module.less"
import { withImpression } from "@src/lib/impression"

const CTA_TYPE = "Use of Proceeds Hero"

const proceedObj = (icon, label, proceedId) => ({ icon, label, proceedId })

/**
 * NOTE: The `label` field is what's actually presented to the user, BUT
 * `proceedId` must match whatever Lightning App expects. These are kept
 * as different fields here for that reason, since `proceedId` should never
 * change UNLESS Lightning App changes their expectations
 */
const PROCEEDS = {
  workingCapital: proceedObj(dollar, "Working Capital", "Working Capital"),
  expansion: proceedObj(global, "Expansion", "Expansion"),
  equipment: proceedObj(
    setting,
    "Purchasing Equipment",
    "Purchasing Equipment"
  ),
  inventory: proceedObj(shoppingCart, "Inventory", "Inventory"),
  payroll: proceedObj(audit, "Payroll", "Payroll"),
  buyAnExistingBusiness: proceedObj(
    shop,
    "Buy an Existing Business",
    "Buy An Existing Business"
  ),
  other: proceedObj(branches, "Other", "Other"),
}

const UseOfProceedsHeroUI = ({
  applyUrl,
  ctaType,
  title,
  subTitle,
  proceeds,
}) => {
  return (
    <div>
      <div className={styles.main}>
        <CenteredContainer>
          <Title className={styles.title} size="x-large">
            {title}
          </Title>
          <Type className={styles.subTitle}>{subTitle}</Type>
          <div className={styles.imageWrapper}>
            <img
              className={styles.funderaLogo}
              src={funderaLogo}
              alt="Fundera by NerdWallet"
              width={154}
              height={40}
            />
          </div>
          <Proceeds applyUrl={applyUrl} ctaType={ctaType}>
            {proceeds.map(proceed => {
              const { icon, label, proceedId } = PROCEEDS[proceed]
              const href = new URL(applyUrl)
              href.searchParams.append("company_loan_purpose_id", proceedId)
              const link = buildTargetUrl({ href, proceedId }).toString()

              return (
                <ProceedTile
                  key={label}
                  icon={icon}
                  label={label}
                  link={link}
                  ctaType={ctaType}
                />
              )
            })}
          </Proceeds>
        </CenteredContainer>
      </div>
      <CenteredContainer>
        <Disclosure />
      </CenteredContainer>
    </div>
  )
}

UseOfProceedsHeroUI.propTypes = {
  applyUrl: PropTypes.string.isRequired,
  ctaType: PropTypes.string.isRequired,
  proceeds: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(PROCEEDS)))
    .isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
}

UseOfProceedsHeroUI.defaultProps = {}

const UseOfProceedsHeroWithImpression = withImpression(
  UseOfProceedsHeroUI,
  {
    eventType: "product_impression",
    payload: ({ props }) => ({
      ...buildElementPositionAttributes({
        sectionName: props.ctaType,
      }),
      ...buildProductShoppingAttributes(),
      entity_name: props.ctaType,
      destination_url: props.applyUrl,
    }),
  },
  {
    propWithImpression: "applyUrl",
  }
)

const UseOfProceedsHero = props => {
  const { ctaType } = props
  const applyUrl = useApplyUrl({
    cta_type: ctaType,
  })

  return <UseOfProceedsHeroWithImpression {...props} applyUrl={applyUrl} />
}

UseOfProceedsHero.propTypes = {
  ...UseOfProceedsHeroUI.propTypes,
  ctaType: PropTypes.string,
}

UseOfProceedsHero.defaultProps = {
  ctaType: CTA_TYPE,
  proceeds: [
    "buyAnExistingBusiness",
    "workingCapital",
    "expansion",
    "payroll",
    "equipment",
    "other",
  ],
  subTitle:
    "See personalized loan options for your business. Choose why you need financing to get started.",
  title: (
    <>
      Small-Business Loans:
      <br />
      Compare and Apply
    </>
  ),
}

export default UseOfProceedsHero
