import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"

import { ContentImpression } from "@src/lib/impression"
import classNames from "classnames"

import FaqSection from "@src/components/FaqSection"
import StructuredContentRenderer from "@src/components/StructuredContentRenderer"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import AuthorsSection from "@src/components/AuthorsSection"
import NoOp from "@src/components/NoOp"
import LoanProcess from "@src/components/LoanProcess"
import SimpleLendersTable from "@src/components/SimpleLendersTable"
import BusinessLoanCalculator from "@src/components/BusinessLoanCalculator"

import Box from "@src/components/Box"
import CenteredHeader from "@src/components/CenteredHeader"
import DateFormat from "@src/components/DateFormat"
import UseOfProceedsHero from "@src/components/UseOfProceedsHero"
import TrustpilotBanner from "@src/components/Trustpilot/TrustpilotBanner"
import Type, { Title } from "@nerdwallet/react-typography"

import { assignments, variations } from "@src/lib/variations"
import {
  LocationVariantProvider,
  LocationVariantConsumer,
} from "@src/hooks/useLocationVariant"
import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"
import RecapSection from "@src/components/roundup/RecapSection"
import ProductsSection from "@src/components/roundup/ProductSection"
import LendersTableSection from "@src/components/roundup/LendersTableSection"
import ExpandableContent from "@src/components/ExpandableContent"
import Methodology from "@src/components/roundup/Methodology"
import NextStepsWrapper from "@src/components/NextStepsWrapper"
import { useAnalyticsPageTrack } from "@src/hooks/useAnalyticsPageTrack"
import { getCanonicalLink } from "@src/lib/helpers"
import useFeatureFlag from "@src/hooks/useFeatureFlag"
import NEXT_STEPS from "./alternate-lending-roundup-next-steps"

import styles from "./alternate-lending-roundup.module.less"
import { CenteredContainer } from "@nerdwallet/react-layout"

const isValidSection = section => !!section.marketplaceEntity

const ProductTable = ({
  validSections,
  productDescription,
  isContentTarget,
  displaySummaryTable,
  fullWidthCategory,
  useFullWidthContainer,
  showBestForText,
}) => {
  const replaceReviewLink = useFeatureFlag(
    "replace-review-link-on-summary-table"
  )
  const removeProductCardLoanOptionCta = useFeatureFlag(
    "lending-ru-remove-product-card-loan-option-cta"
  )

  const LendersSectionWrapper = useFullWidthContainer ? CenteredContainer : NoOp

  // Check both useFullWidthContainer and fullWidthCategory next because
  // full-width could be necessary because of the flag
  // GATSBY_FF_LENDING_RU_REPLACE_TABLE_WITH_CONTAINER_BOXES while
  // fullWidthCagegory is false
  const ProductsSectionWrapper =
    useFullWidthContainer && fullWidthCategory ? NoOp : CenteredContainer

  const lendersSection = displaySummaryTable && (
    <LendersSectionWrapper>
      <div
        data-cy="lenders-table"
        className={styles.lendersTableWrapper}
        key="lenders-section"
      >
        <LendersTableSection
          sections={validSections}
          sort={validSections.length >= 4}
          productDescription={productDescription}
        />
      </div>
    </LendersSectionWrapper>
  )

  const productSection = (
    <ProductsSectionWrapper>
      <Box className={styles.defaultMargin} key="product-section">
        <ProductsSection
          sections={validSections}
          isContentTarget={isContentTarget}
          hideLoanOptionCta={removeProductCardLoanOptionCta}
          fullWidthCategory={fullWidthCategory}
          showBestForText={showBestForText}
        />
      </Box>
    </ProductsSectionWrapper>
  )

  if (replaceReviewLink) {
    return [productSection, lendersSection]
  }

  return [lendersSection, productSection]
}

ProductTable.propTypes = {
  validSections: PropTypes.array.isRequired,
  productDescription: PropTypes.string.isRequired,
  displaySummaryTable: PropTypes.bool.isRequired,
  showBestForText: PropTypes.bool.isRequired,
}

const AlternateLendingRoundUp = ({ data, location }) => {
  const { smbRoundup: roundup, trustpilotData } = data

  useAnalyticsPageTrack(roundup.contentId)
  const addLoanProcess = useFeatureFlag("lending-ru-add-loan-process")
  const addCreditScoreSignupCTA = useFeatureFlag(
    "lending-ru-add-credit-score-signup-cta"
  )
  const hideRecapSection = useFeatureFlag("hide-recap-section")
  const displayBusinessCalculator = "display-business-loan-calculator"
  const addSimpleLendersTable = useFeatureFlag("lending-ru-add-simple-table")
  const addNextSteps = useFeatureFlag("lending-ru-add-next-steps")
  const addHero = useFeatureFlag("lending-ru-add-hero")
  const addTrustpilotBanner = useFeatureFlag("lending-ru-add-trustpilot-banner")
  const replaceTableWithContainerBoxes = useFeatureFlag(
    "lending-ru-replace-table-with-container-boxes"
  )
  const fullWidthCategory = useFeatureFlag("lending-ru-full-width-category")
  const isFullWidthContainer =
    replaceTableWithContainerBoxes || fullWidthCategory
  const ContentWrapper = isFullWidthContainer ? CenteredContainer : NoOp
  const validSections = roundup.roundupSections.filter(isValidSection)

  const header = (
    <Header attributionTopic={roundup.attributionTopic} hideBanner />
  )

  const alternateHeaderContent = addHero && (
    <>
      <UseOfProceedsHero />
      {addTrustpilotBanner && (
        <TrustpilotBanner
          name={trustpilotData.name}
          score={trustpilotData.score}
        />
      )}
      <CenteredHeader
        assigningEditor={roundup.assigningEditor}
        authors={roundup.authors}
        date={roundup.displayDate}
        title={roundup.title}
      >
        <StructuredContentRenderer>
          {roundup.aboveSections}
        </StructuredContentRenderer>
      </CenteredHeader>
    </>
  )

  const headerContent = (
    <HeaderContent
      title={roundup.title}
      date={roundup.displayDate}
      authors={roundup.authors}
      hideAuthorByline={!roundup.showAuthorByline}
      synopsis={
        <StructuredContentRenderer>
          {roundup.synopsis}
        </StructuredContentRenderer>
      }
      breadcrumb={roundup.breadcrumb}
      featuredImage={roundup.featuredMedia}
      headerSpace={9}
      assigningEditor={roundup.assigningEditor}
      hideEditorialStandards={!roundup.showEditorialDisclaimer}
    />
  )
  const footer = (
    <Footer attributionTopic={roundup.attributionTopic} hideBanner />
  )

  return (
    <ContentImpression contentId={roundup.contentId}>
      <LocationVariantProvider location={location} assignments={assignments}>
        <ApplyUrlProvider
          location={location}
          attributionTopic={roundup.attributionTopic}
        >
          <Page
            title={roundup.title}
            link={getCanonicalLink(roundup)}
            featuredImage={roundup.featuredMedia}
            seo={roundup.seo}
            jsonld={roundup.jsonld}
            header={header}
            headerContent={alternateHeaderContent || headerContent}
            footer={footer}
            contentWrapper={isFullWidthContainer ? NoOp : CenteredContainer}
          >
            <main data-template="alternate-lending-roundup">
              <ContentWrapper>
                {!addHero && (
                  <div className={styles.defaultMargin}>
                    <ExpandableContent>
                      <StructuredContentRenderer>
                        {roundup.aboveSections}
                      </StructuredContentRenderer>
                    </ExpandableContent>
                  </div>
                )}
                {addSimpleLendersTable && (
                  <Box className={styles.defaultMargin}>
                    <SimpleLendersTable sections={validSections} />
                  </Box>
                )}
              </ContentWrapper>

              <ProductTable
                validSections={validSections}
                productDescription={roundup.seo.keyword}
                displaySummaryTable={!addSimpleLendersTable}
                fullWidthCategory={fullWidthCategory}
                useFullWidthContainer={isFullWidthContainer}
                showBestForText
              />

              <ContentWrapper>
                {displayBusinessCalculator && (
                  <BusinessLoanCalculator title="Understand the cost of a business loan" />
                )}
              </ContentWrapper>

              {replaceTableWithContainerBoxes &&
              roundup.belowSectionsWithTable ? (
                <div className={styles.defaultMargin}>
                  <StructuredContentRenderer>
                    {roundup.belowSectionsWithTable}
                  </StructuredContentRenderer>
                </div>
              ) : (
                <ContentWrapper>
                  <div className={styles.defaultMargin}>
                    <StructuredContentRenderer>
                      {roundup.belowSections}
                    </StructuredContentRenderer>
                  </div>
                </ContentWrapper>
              )}

              <ContentWrapper>
                <Box className={styles.defaultMargin}>
                  <Type inline italic>
                    Last updated on{" "}
                    <DateFormat timestamp={roundup.displayDate} />
                  </Type>
                </Box>
                {!hideRecapSection && (
                  <Box
                    className={classNames(
                      styles.defaultMargin,
                      styles.recapSection
                    )}
                  >
                    <RecapSection
                      title={roundup.title}
                      sections={validSections}
                    />
                  </Box>
                )}
                <LocationVariantConsumer id={variations.LOAN_PROCESS}>
                  {isVariant =>
                    isVariant &&
                    addLoanProcess && (
                      <Box marginY={5}>
                        <Title
                          size="large"
                          component="h3"
                          align="center"
                          className={styles.loanProcessTitle}
                        >
                          How Fundera by NerdWallet works
                        </Title>
                        <LoanProcess />
                      </Box>
                    )
                  }
                </LocationVariantConsumer>

                {roundup.methodology && (
                  <Box className={styles.methodology}>
                    <Methodology methodology={roundup.methodology} />
                  </Box>
                )}

                {addCreditScoreSignupCTA && roundup.creditScoreHouseAd && (
                  <div className={styles.creditScoreHouseAd}>
                    <StructuredContentRenderer>
                      {roundup.creditScoreHouseAd}
                    </StructuredContentRenderer>
                  </div>
                )}

                {roundup.faqs && (
                  <Box className={styles.faqBox}>
                    <FaqSection faqs={roundup.faqs} />
                  </Box>
                )}
              </ContentWrapper>
            </main>
            <ContentWrapper>
              <Box className={styles.defaultMargin}>
                <AuthorsSection authorBoxes={roundup.authorBox} />
              </Box>

              {addNextSteps && (
                <NextStepsWrapper
                  category={roundup.primaryCategory}
                  nextSteps={NEXT_STEPS}
                />
              )}
            </ContentWrapper>
          </Page>
        </ApplyUrlProvider>
      </LocationVariantProvider>
    </ContentImpression>
  )
}

AlternateLendingRoundUp.propTypes = {
  data: PropTypes.shape({
    smbRoundup: PropTypes.shape({
      contentId: PropTypes.number.isRequired,
      customPath: PropTypes.string,
      breadcrumb: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        page: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      jsonld: PropTypes.object,
      displayDate: PropTypes.string,
      attributionTopic: PropTypes.string,
      assigningEditor: PropTypes.object,
      authors: PropTypes.array,
      primaryCategory: PropTypes.shape({
        name: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }).isRequired,
      showAuthorByline: PropTypes.bool,
      showEditorialDisclaimer: PropTypes.bool,
      synopsis: PropTypes.any,
      aboveSections: PropTypes.any,
      roundupSections: PropTypes.array.isRequired,
      belowSections: PropTypes.any,
      faqs: PropTypes.array,
      authorBox: PropTypes.array,
      seo: PropTypes.shape({ keyword: PropTypes.string }).isRequired,
      featuredMedia: PropTypes.shape({}).isRequired,
      methodology: PropTypes.shape({}),
      creditScoreHouseAd: PropTypes.any,
      belowSectionsWithTable: PropTypes.any,
    }).isRequired,
    trustpilotData: PropTypes.shape({
      name: PropTypes.shape({
        identifying: PropTypes.string,
      }),
      score: PropTypes.shape({
        stars: PropTypes.number,
        trustScore: PropTypes.number,
      }),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default AlternateLendingRoundUp

export const query = graphql`
  query ($slug: String!) {
    trustpilotData(name: { identifying: { eq: "fundera.com" } }) {
      displayName
      name {
        identifying
      }
      numberOfReviews {
        total
      }
      score {
        stars
        trustScore
      }
    }
    smbRoundup(slug: { eq: $slug }) {
      contentId
      customPath
      title
      link
      jsonld
      displayDate
      attributionTopic
      assigningEditor {
        id
        name
        link
      }
      authors {
        id
        name
        link
        url: link
      }
      primaryCategory {
        name
        link
      }
      breadcrumb {
        slug
        page {
          title
        }
      }
      showAuthorByline
      showEditorialDisclaimer
      synopsis
      aboveSections
      roundupSections {
        categoryName
        categoryDescription
        marketplaceEntity {
          id
          status
          ... on SmallMediumBusinessLoansOfferV2 {
            prosBullets
            consBullets
            image {
              alt
              sourceMedium
            }
            displayName
            maxLoanAmount
            maxLoanTerm
            tagline
            qualificationsBullets
            reviewLink
            cta {
              link
              text
              subtext
            }
            institution {
              id
              name
            }
            product {
              id
            }
            productType
            placementDrivers {
              id
              label
              value
              tooltip
            }
            productLabel
          }
        }
      }
      belowSections
      creditScoreHouseAd
      belowSectionsWithTable
      faqs {
        question
        answer
        answerSchema
      }
      authorBox: authors {
        id
        name
        link
        contactEmail
        twitter
        facebook
        linkedin
        marketplaceBio
        description
        avatarUrls {
          size
          url
        }
      }
      featuredMedia {
        croppedImages {
          sourceUrl
          width
          height
        }
      }
      methodology
      seo {
        title
        description
        keyword
        facebook {
          title
          description
        }
        twitter {
          title
          description
        }
      }
    }
  }
`
