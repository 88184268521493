import React from "react"
import PropTypes from "prop-types"

import { findInPlacementDrivers } from "@src/lib/drivers"
import SimpleTableDesktop from "./SimpleTableDesktop"
import SimpleTableMobile from "./SimpleTableMobile"

const isValidValue = value => value !== null && value !== 0
const standardizeValue = value => (isValidValue(value) ? value : null)

const getMaxLoanTerm = ({ maxLoanTerm }) => {
  if (!isValidValue(maxLoanTerm)) return null

  const years = Math.floor(maxLoanTerm / 12)
  const months = maxLoanTerm % 12

  const appendUnit = (value, unit) => {
    if (value === 0) return ""

    return value > 1 ? `${value} ${unit}s` : `${value} ${unit}`
  }

  const yearPart = appendUnit(years, "year")
  const monthPart = appendUnit(months, "month")
  const connector = years && months ? " and " : ""

  return `${yearPart}${connector}${monthPart}`
}

const getFromPlacementDriver = ({ placementDrivers }, driverName) =>
  standardizeValue(findInPlacementDrivers(placementDrivers, driverName)?.value)

const buildLender = ({ marketplaceEntity, categoryName }) => ({
  name: standardizeValue(marketplaceEntity.institution?.name),
  reviewLink: standardizeValue(marketplaceEntity.reviewLink),
  category: standardizeValue(categoryName),
  linkTarget: standardizeValue(marketplaceEntity.displayName),
  bestFor: standardizeValue(categoryName),
  maxLoanAmount: standardizeValue(marketplaceEntity.maxLoanAmount),
  minCreditScore: getFromPlacementDriver(marketplaceEntity, "minCreditScore"),
  estApr: getFromPlacementDriver(marketplaceEntity, "estApr"),
  maxLoanTerm: getMaxLoanTerm(marketplaceEntity),
})

const SimpleLendersTable = ({ sections }) => {
  const lenders = sections.map(buildLender)
  return (
    <>
      <SimpleTableDesktop lenders={lenders} />
      <SimpleTableMobile lenders={lenders} />
    </>
  )
}

SimpleLendersTable.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SimpleLendersTable
