import React from "react"
import PropTypes from "prop-types"

import { EditorialStandards } from "@nerdwallet/currency"

import { ContentPageHeader, ByLine } from "@src/components/Page"
import CentralizedColumn from "@src/components/CentralizedColumn"

import styles from "./CenteredHeader.module.less"

const CenteredHeader = ({
  assigningEditor,
  authors,
  title,
  date,
  children,
}) => {
  return (
    <CentralizedColumn>
      <div className={styles.wrapper}>
        <ContentPageHeader
          authoringDate={date}
          authors={authors}
          title={title}
          titleComponent="h2"
          headerSpace={12}
          hideAdvertiserDisclosure
          centerTitle
        />
        <div className={styles.block}>
          <ByLine
            authoringDate={date}
            authors={authors}
            assigningEditor={assigningEditor}
            oneLine
          />
        </div>

        {children}

        <div className={styles.editorial}>
          <EditorialStandards />
        </div>
      </div>
    </CentralizedColumn>
  )
}

CenteredHeader.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ).isRequired,
  assigningEditor: PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  children: PropTypes.node,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default CenteredHeader
