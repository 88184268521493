import React from "react"
import PropTypes from "prop-types"

import TrustpilotWidget from "./TrustpilotWidget"

import bbbSeal from "./svg/bbb-accredited-business.svg"
import goddadySeal from "./svg/goddady-verified-and-secure.svg"

import styles from "./TrustpilotBanner.module.less"

const TrustpilotBanner = props => {
  const { name, score } = props
  return (
    <div className={styles.wrapper}>
      <div className={styles.trustpilotWidget}>
        <TrustpilotWidget name={name} score={score} />
      </div>
      <div className={styles.seals}>
        <img
          className={styles.seal}
          src={bbbSeal}
          alt="bbb accredited business seal"
          width={80}
          height={60}
        />
        <img
          className={styles.seal}
          src={goddadySeal}
          alt="goddady verified and secure seal"
          width={80}
          height={30}
        />
      </div>
    </div>
  )
}

TrustpilotBanner.propTypes = {
  displayName: PropTypes.string,
  numberOfReviews: PropTypes.shape({
    total: PropTypes.number,
  }),
  name: PropTypes.shape({
    identifying: PropTypes.string,
  }),
  score: PropTypes.shape({
    stars: PropTypes.number,
    trustScore: PropTypes.number,
  }),
}

TrustpilotBanner.defaultProps = {
  displayName: "Fundera by NerdWallet",
  name: { identifying: "fundera.com" },
  numberOfReviews: {
    total: 865,
  },
  score: {
    stars: 4.5,
    trustScore: 4.6,
  },
}

export default TrustpilotBanner
