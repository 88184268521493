import React from "react"
import PropTypes from "prop-types"

import styles from "./TrustpilotWidget.module.less"

import TrustpilotLogo from "./TrustpilotLogo"
import TrustpilotStars from "./TrustpilotStars"
import Type from "@nerdwallet/react-typography"

const TrustpilotWidget = props => {
  const { name, score } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.scoreAndQualifier}>
        <Type bold size={1}>{`${score.trustScore} Excellent`}</Type>
      </div>
      <div className={styles.stars}>
        <TrustpilotStars score={score} identifyingName={name.identifying} />
      </div>
      <div className={styles.logo}>
        <TrustpilotLogo identifyingName={name.identifying} />
      </div>
    </div>
  )
}

TrustpilotWidget.propTypes = {
  displayName: PropTypes.string,
  numberOfReviews: PropTypes.shape({
    total: PropTypes.number,
  }),
  name: PropTypes.shape({
    identifying: PropTypes.string,
  }),
  score: PropTypes.shape({
    stars: PropTypes.number,
    trustScore: PropTypes.number,
  }),
}

TrustpilotWidget.defaultProps = {
  displayName: "Fundera by NerdWallet",
  name: { identifying: "fundera.com" },
  numberOfReviews: {
    total: 865,
  },
  score: {
    stars: 4.5,
    trustScore: 4.6,
  },
}

export default TrustpilotWidget
