import React from "react"

import { AdvertiserDisclosure } from "@nerdwallet/currency"

import styles from "./Disclosure.module.less"

const Disclosure = () => {
  return (
    <div className={styles.disclosure}>
      <div className={styles.disclosureMessage}>
        Here is a{" "}
        <a className={styles.disclosureLink} href="/blog/our-partners/">
          list of our partners
        </a>{" "}
        and{" "}
        <a
          className={styles.disclosureLink}
          href="/blog/advertiser-disclosure/"
        >
          here&apos;s how we make money
        </a>
        .
      </div>
      <AdvertiserDisclosure type={"credit_cards"} shouldDelay={false} />
    </div>
  )
}

export default Disclosure
