import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Columns from "@src/components/Columns/Columns"
import Column from "@src/components/Columns/Column"
import { CenteredContainer } from "@nerdwallet/react-layout"

import styles from "./CentralizedColumn.module.less"

const CentralizedColumn = ({ children, small }) => {
  return (
    <CenteredContainer
      className={classNames(styles.container, {
        [styles.small]: small,
      })}
    >
      <Columns>
        <Column width={{ mobile: 12, desktop: 12 }}>{children}</Column>
      </Columns>
    </CenteredContainer>
  )
}

CentralizedColumn.propTypes = {
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
}

CentralizedColumn.defaultProps = {
  small: false,
}

export default CentralizedColumn
