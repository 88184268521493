import React from "react"
import PropTypes from "prop-types"

const TrustpilotStar = ({ index, state }) => {
  const stepSize = 51.248667

  const background = {
    empty: {
      left: "#dcdce6",
      right: "#dcdce6",
    },
    full: {
      left: "#00B67A",
      right: "#00B67A",
    },

    half: {
      left: "#00B67A",
      right: "#dcdce6",
    },
  }

  return (
    <g className="tp-star">
      <path
        className="tp-star__canvas"
        fill={background[state]?.right}
        d={`M${stepSize * index} 46.330002h46.375587V0h-46.375587z`}
      ></path>
      <path
        className="tp-star__canvas--half"
        fill={background[state]?.left}
        d={`M${stepSize * index} 46.330002h23.187793V0h-23.187793z`}
      ></path>
      <path
        className="tp-star__shape"
        d={`M${
          39.533936 + stepSize * index
        } 19.711433l-26.3029 19.089218 3.837419-11.797827-10.047304-7.291391h12.418974l3.837418-11.798624 3.837418 11.798624h12.418975zm-16.255436 11.798642l7.183595-1.509576 2.862114 8.800152-10.045709-7.290576z`}
        fill="#FFF"
      ></path>
    </g>
  )
}

TrustpilotStar.propTypes = {
  index: PropTypes.number,
  state: PropTypes.oneOf(["full", "empty", "half"]),
}

const TrustpilotStars = ({ score, identifyingName }) => {
  const rating = score.stars
  return (
    <a
      id="tp-widget-stars"
      className="profile-url"
      target="_blank"
      rel="noreferrer"
      href={`https://www.trustpilot.com/review/${identifyingName}?utm_medium=trustbox&amp;utm_source=Horizontal`}
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <svg
        role="img"
        aria-labelledby="starRating"
        viewBox="0 0 251 46"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <title id="starRating" lang="en">
          {`${score.trustScore} out of five star rating on Trustpilot`}
        </title>
        {Array(5)
          .fill()
          .map((_, index) => {
            const starNumber = index + 1
            let state = rating >= starNumber ? "full" : "empty"
            if (rating > index && rating < starNumber) state = "half"

            return (
              <TrustpilotStar
                state={state}
                index={index}
                key={`star${starNumber}`}
              />
            )
          })}
      </svg>
    </a>
  )
}

TrustpilotStars.propTypes = {
  identifyingName: PropTypes.string,
  score: PropTypes.shape({
    stars: PropTypes.number,
    trustScore: PropTypes.number,
  }),
}

export default TrustpilotStars
