import React from "react"
import PropTypes from "prop-types"

import Type from "@nerdwallet/react-typography"

import styles from "./ProceedTile.module.less"

const ProceedTile = ({ icon, label, link, ctaType }) => {
  return (
    <a
      className={styles.tile}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      data-cta-type={ctaType}
      data-mclick={true}
    >
      <img
        className={styles.icon}
        src={icon}
        alt={label}
        height="40"
        width="40"
      />
      <Type className={styles.label}>{label}</Type>
    </a>
  )
}

ProceedTile.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  ctaType: PropTypes.string,
}

export default ProceedTile
